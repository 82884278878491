import { BaseEntityModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlModel, FormControlOptionModel, FormControlTextModel } from '@common-src/model/form-control';
import { QueryPageModel } from '@common-src/model/query-model';
import CommonService from '@common-src/service/common';
import { FacilityType } from '@common-src/model/enum';
import { generateUUID } from '@common-src/utils/base_util';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
export class FacilityEntityModel extends BaseEntityModel {
    constructor(type: FacilityType = FacilityType.FACILITY) {
        super();
        this.type = type;
    }
    type: FacilityType;
    linkId: string = undefined;

    @FormControl({
        label: '名称',
        type: FormControlType.TEXT,
        required: true,
        max: 255,
        index: 2
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '类型',
        type: FormControlType.SELECT,
        required: true,
        optionsPromise: CommonService.getAllFacilityType,
        optionsPromiseParam: 'type',
        index: 3,
        message: '请选取已配置类型'
    } as FormControlOptionModel)
    typeId: string = undefined;
    typeName: string = undefined;

    @FormControl({
        label: '空间位置',
        type: FormControlType.SELECT_SPACE,
        required: false,
        index: 4
    } as FormControlModel<string>)
    spaceId: string = undefined;
    spaceName: string = undefined;

    get SpaceName() {
        return _.takeRight(this.path, 2).join(' \\ ') || this.spaceName;
    }

    /**
     * 是否有子设备
     */
    hasChildren: boolean = undefined;

    /**
     * 子设备
     */
    children: Array<FacilityEntityModel> = undefined;
    childrenTotalCount: number = 0;
    childrenPage: number = 0;
    parent: FacilityEntityModel;
    expand: boolean = false;
    parentId: string = undefined;
    check: boolean = false;
    path: Array<string> = undefined;

    get Children() {
        if (this.children && this.children.length > 0) {
            if (this.children.length === this.childrenTotalCount) {
                return this.children;
            }
            return [...this.children, {
                uuid: generateUUID(),
                id: null,
                parent: this,
                name: '点击加载更多...'
            }];
        }
        return this.children;
    }

    static getTableColumns() {
        return [
            {
                title: '名称',
                dataIndex: 'name',
                scopedSlots: { customRender: 'name' }
            },
            {
                title: '类型',
                dataIndex: 'typeName'
            },
            {
                title: '位置',
                dataIndex: 'SpaceName'
            },
            {
                title: '创建时间',
                dataIndex: 'createdTime'
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        // if (this.hasChildren) {
        //     this.children = [];
        // }
        return this;
    }

    toService() {
        // const data = super.toService();
        // delete data.spaceName;
        // delete data.typeName;
        // return data;
        return {
            id: this.id,
            parentId: this.parentId,
            type: this.type,
            typeId: this.typeId,
            name: this.name,
            spaceId: this.spaceId
        };
    }
}

export class FacilityQueryModel extends QueryPageModel {
    @QueryControl({
        label: '安装位置',
        type: QueryControlType.SELECT_SPACE
    })
    spaceId: string = undefined;

    @QueryControl({
        label: '名称',
        type: QueryControlType.TEXT
    })
    name: string = undefined;

    forceRoot: boolean = true;
    parentId: string = undefined;

    toService() {
        return {
            params: {
                name: this.name,
                spaceId: this.spaceId,
                forceRoot: this.forceRoot,
                parentId: this.parentId
            }
        };
    }
}
